/*This is the blacked-out background*/
.background{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
}

/*This the actual content of the popup*/
.popupMain{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    height: auto;
    background-color: #7f8386;
    box-shadow: 0 0 30px  rgba(150, 150, 150, .75);
    color: white;
    text-align: center;

    .header{
        margin-top: 0;
        height: 5vh;
        justify-content: center;
        h1{
            margin-top: 0;
            font-size: 220%;
        }
    }
    .panels{
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        display: flex !important;
        flex-shrink: 1;
        flex-direction: row;
    }
}

/*This is an individual half of the component*/
.sidePanel{
    width: 50%;
    height: auto;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    margin: 0;

    img{
        margin: 2.5%;
        width: 80%;
        height: 80%;
        object-fit: cover;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
    }
    .line{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0;
        *{
            margin-left: 2%;
            margin-right: 2%;
        }
        select {
            background: none;
            max-width: 75%;
            font: inherit;
            color: white;
            font-size: larger;
            text-align: center;
            border: 2px solid lightgray;
        }
        option{
            color: black;
        }
        input{
            font: inherit;
            text-align: center;
        }
    }
}

/*These are the buttons*/
.buttonPanel{
    height: 10vh;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    display: flex;
    width: 75vw;
    button{
        width: 40%;
        margin-left: 5%;
        margin-right: 5%;
        height: 75%;
        background: none;
        border: 2px solid lightgray;
        color: white;
        font: inherit;
        font-weight: bold;
        font-size: x-large;
    }
    button:hover{
        background-color: #a1a8af;
    }
}


@media (max-width: 768px) {
    .popupMain {
        width: 95vw;
        height: auto;
        flex-direction: column;
    }

    .panels {
        width: 100%;
        flex-direction: column;
        display: flex !important;
    }

    .sidePanel {
        width: 100%;
        flex-direction: column;
    }

    .buttonPanel {
        align-items: center;
        width: 100%;
        flex-direction: column;
    }

    .buttonPanel button {
        width: 80%; /* Buttons should take up most of the width */
        margin: 10px 0; /* Add margin between buttons */
    }
}

input{
    color: black;
}